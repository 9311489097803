
import { useState, useEffect } from 'react';

const AdvertisementBanner = () => {
    const [isBannerVisible, setBannerVisible] = useState(true);
    const [isMobileView, setIsMobileView] = useState(false);

    // Check sessionStorage for banner visibility on mount
    useEffect(() => {
        const isClosed = sessionStorage.getItem('closedBanner');
        if (isClosed) {
            setBannerVisible(false);
        }

        // Function to handle screen resizing
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 992); // Set mobile view for screens 992px and below
        };

        // Initial check and event listener
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);


    // Function to close the banner
    const handleCloseBanner = () => {
        sessionStorage.setItem('closedBanner', 'true');
        setBannerVisible(false);
    };

    // Render nothing if the banner is not visible
    if (!isBannerVisible) {
        return null;
    }

    return (
        <div className={`advertisement-banner-container ${isMobileView ? 'mobile-view' : ''}`}>
            {isMobileView ? (
                // Mobile view
                <div className="advertisement-mobile-banner-content">
                    <div className="advertisement-mobile-banner-first-row">
                        <img src="/images/bengaluru-tech-summit-mobile.png" alt="Ajackus in Bengaluru Tech Summit 2024" />
                        <div className="advertisement-btn-container">
                            <a href="https://calendly.com/ajackus-rahul/bts-2024" className="advertisement-book-now-btn" target="_blank" rel="noopener noreferrer">Book Now</a>
                            <span className="advertisement-banner-close-btn pr-1" onClick={handleCloseBanner}>X</span>
                        </div>
                    </div>

                    <div className="advertisement-mobile-banner-second-row">
                        <div className="advertisement-banner-place-mobile">
                            <img src="/images/distance-mobile.png" alt="Bangalore Palace" className='mr-1' />
                            <span>Bangalore Palace</span>
                        </div>
                        <div className="advertisement-banner-date-mobile">
                            <img src="/images/calendar_month-mobile.png" alt="19-21 November 2024" className='mr-1' />
                            <span>19-21 November</span>
                        </div>
                        <div className="advertisement-banner-booth-mobile">
                            <img src="/images/storefront-mobile.png" alt="Store Front" className='mr-1' />
                            <span>B27</span>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="advertisement-banner-left-container">
                        <div>
                            <img src="/images/bengaluru-tech-summit.png" alt="Ajackus in Bengaluru Tech Summit 2024" />
                        </div>
                        <div className="advertisement-banner-desc">
                            <span>The Future of Innovation Awaits at Bengaluru Tech Summit 2024</span>
                        </div>
                    </div>

                    <div className="advertisement-banner-right-container">
                        <div className="advertisement-banner-date">
                            <img src="/images/calendar_month.png" alt="19-21 November 2024" className="mr-1" />
                            <span>19-21 November 2024</span>
                        </div>
                        <div className="advertisement-banner-place">
                            <img src="/images/distance.png" alt="Bangalore Palace" className="mr-1" />
                            <span>Bangalore Palace</span>
                        </div>
                        <div className="advertisement-banner-booth">
                            <img src="/images/storefront.png" alt="Store Front" className="mr-1" />
                            <span>Booth No. B27</span>
                        </div>
                    </div>
                    <div className="advertisement-btn-container pr-3">
                        <a href="https://calendly.com/ajackus-rahul/bts-2024" className="advertisement-book-now-btn" target="_blank" rel="noopener noreferrer">Book Now</a>
                        <span className="advertisement-banner-close-btn" onClick={handleCloseBanner}>X</span>
                    </div>
                </>
            )}
        </div>
    );
}

export default AdvertisementBanner