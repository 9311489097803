import NProgress from "nprogress";

const useLoader = () => {
    if (typeof window !== "undefined" && document) {
        NProgress.configure({ showSpinner: false });

        return {
            start: () => NProgress.start(),
            done: () => NProgress.done(),
            isActive: Boolean(NProgress.status),
        };
    }

    return {
        start: () => {},
        done: () => {},
        isActive: false,
    };
};

export default useLoader;
