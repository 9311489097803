import { Popover, Transition } from "@headlessui/react";
import { useRouter } from "next/router";
import { Fragment, useRef } from "react";

export const MenuDropdown = ({ item, renderTrigger }) => {
    const router = useRouter();
    const buttonRef = useRef(null);
    const timeoutDuration = 200;
    let timeout;
    const closePopover = () => {
        return buttonRef.current?.dispatchEvent(
            new KeyboardEvent("keydown", {
                key: "Escape",
                bubbles: true,
                cancelable: true,
            })
        );
    };

    const onMouseEnter = (open) => {
        clearTimeout(timeout);
        if (open) return;
        return buttonRef.current?.click();
    };

    const onMouseLeave = (open) => {
        if (!open) return;
        timeout = setTimeout(() => closePopover(), timeoutDuration);
    };

    return (
        <Popover className="relative inline-block">
            {({ open }) => (
                <>
                    {" "}
                    <Popover.Button
                        ref={buttonRef}
                        onMouseEnter={onMouseEnter.bind(null, open)}
                        onMouseLeave={onMouseLeave.bind(null, open)}
                        className="inline-flex items-center gap-x-1 font-semibold leading-6 text-gray-900 outline-none"
                    >
                        {renderTrigger(open)}
                    </Popover.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
                            <div
                                onMouseEnter={onMouseEnter.bind(null, open)}
                                onMouseLeave={onMouseLeave.bind(null, open)}
                                className="w-screen max-w-sm flex-auto rounded-3xl bg-white p-4 text-sm mt-1 leading-6 shadow-lg ring-1 ring-gray-900/5 overflow-hidden"
                            >
                                {item.children.map((item, index) => (
                                    <div
                                        key={index}
                                        className={`flex gap-x-6 relative rounded-lg p-4 hover:bg-gray-50 ${
                                            item.links.includes(router.pathname)
                                                ? "bg-gray-50"
                                                : ""
                                        }`}
                                    >
                                        {item.icon && (
                                            <div>
                                                <div className="rounded-lg p-3 bg-gray-50">
                                                    <item.icon
                                                        className="h-6 w-6 text-gray-40"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <div>
                                            <a
                                                href={item.href}
                                                className="font-semibold text-gray-900"
                                            >
                                                {item.name}
                                                <span className="absolute inset-0" />
                                            </a>
                                            {item.description && (
                                                <p className="mt-1 text-gray-600">
                                                    {item.description}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    );
};
